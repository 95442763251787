import * as TYPES from './countyFilterTypes';
import { LocalStorage, isEmpty } from 'utils';
const Storage = new LocalStorage();

const cookie = Storage.getJSON(TYPES.FILTER_COUNTY_COOKIE);

const initialState = {
  categoryList: cookie && typeof cookie.categoryList === typeof [] ? cookie.categoryList : [],
  reset: {},
  current: cookie && cookie.selected ? cookie.selected : {},
  selected: cookie && cookie.selected ? cookie.selected : {},
  message: '',
  last: {},
  hover: false,
  headerHover: false,
  animationDuration: 1000,
  animationDone: true,
  position: {
    x: 0,
    y: 0
  },
  loading: false,
  error: false,
  expanded: false,
  initialized: false,
  isModalOpen: false,
  forceMode: false
};

if (!cookie) {
    Storage.set(TYPES.FILTER_COUNTY_COOKIE, initialState);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.FILTER_SET_MESSAGE: {
      return {
        ...state,
        message: action.message
      };
    }
    case TYPES.FILTER_TOGGLE_MODAL: {
      const { shouldOpen, forceMode } = action.value;
      return {
        ...state,
        isModalOpen: shouldOpen,
        forceMode: forceMode
      };
    }
    case TYPES.FILTER_SET_CURRENT:
      return {
        ...state,
        loading: true
      };
    case TYPES.FILTER_RESET:
      return {
        ...state,
        current: action.category,
        selected: action.category,
        last: action.category,
        message: ''
      };
    case TYPES.FILTER_CURRENT: {
      return {
        ...state,
        current: action.value,
        loading: false,
        error: false
      };
    }
    case TYPES.FILTER_SET_SELECTED:
      return {
        ...state,
        loading: true
      };
    case TYPES.FILTER_SELECTED:
      return {
        ...state,
        last: state.selected,
        selected: action.selected,
        current: action.selected,
        loading: false,
        error: false
      };
    case TYPES.FILTER_HOVER_START:
      return {
        ...state,
        hover: true
      };
    case TYPES.FILTER_HOVER_END:
      return {
        ...state,
        hover: false
      };
    case TYPES.FILTER_HEADER_HOVER:
      return {
        ...state,
        headerHover: action.value
      };
    case TYPES.FILTER_ANIMATION_START: {
      return {
        ...state,
        animationDone: false,
        position: action.value
      };
    }
    case TYPES.FILTER_ANIMATION_END: {
      return {
        ...state,
        animationDone: true
      };
    }
    case TYPES.FILTER_EXPAND_COLLAPSE: {
      return {
        ...state,
        expanded: action.value
      };
    }
    case TYPES.FILTER_ERROR: {
      return {
        ...state,
        loading: false,
        error: true
      };
    }
    case TYPES.FILTER_REQUESTED_STARTED: {
      return {
        ...state,
        loading: true
      };
    }
    case TYPES.FILTER_REQUESTED_FINISH: {
      return {
        ...state,
        categoryList: action.categoryList,
        reset: action.categoryList[0],
        current: !isEmpty(state.current) ? state.current : action.categoryList[0],
        selected: !isEmpty(state.selected) ? state.selected : action.categoryList[0],
        last: !isEmpty(state.last) ? state.last : action.categoryList[0],
        loading: false,
        initialized: true,
      };
    }
    case TYPES.FILTER_QUERY_COOKIE: {
      return {
        ...state,
        current: action.selected,
        selected: action.selected,
        loading: false
      };
    }
    default:
      return state;
  }
};
