export const BLOCKS = {
  HERO: 'HeroBlock',
  SUB_HERO: 'SubHeroBlock',
  LIST: 'ListBlock',
  CALENDAR_LIST: 'CalendarListBlock',
  PRIORITIZED_ARTICLE: 'PrioritizedArticleBlock',
  ACTIVITY: 'ActivityBlock',
  QUESTION: 'LinkTextBlock',
  CAROUSEL: 'CarouselBlock',
  CARD_CAROUSEL: 'CardCarouselBlock',
  CARD_CAROUSEL_ITEM: 'CardCarouselBlockItem',
  PAGE_LIST: 'PageListBlock',
  COUNTY_FILTER: 'CountyFilterBlock',
  LINK_LIST: 'LinkListBLock',
  FILTER_AREA: 'AreaFilterBlock',
  NEWS_BLOCK: 'NewsBlock',
  ICON_LIST_BLOCK: 'IconListBlock',
  CONTACT_LIST_BLOCK: 'ContactListBlock',
  QUOTE_BLOCK: 'QuoteBlock',
  CONTACT_BLOCK: 'ContactBlock',
  CUSTOMAREA_BLOCK: 'CustomAreaBlock',
  CONTRACT_BLOCK: 'ContractBlock',
  CONTRACT_CATEGORY_BLOCK: 'ContractCategoryBlock',
  INSTRUCTION_CONTAINER_BLOCK: 'InstructionContainerBlock',
  INSTRUCTION_BLOCK: 'InstructionBlock',
  EPIFORM: 'FormContainerBlock',
  EXTENDED_EPIFORM: 'ExtendedFormContainerBlock',
  LINK_BLOCK: 'LinkBlock',
  PUFF_LIST_BLOCK: 'PuffListBlock',
  MULTINET_COURSE_BLOCK: 'MultinetCourseBlock',
  MULTINET_DYNAMIC_FILTER_BLOCK: 'MultinetDynamicFilterBlock',
  MULTIPLE_ITEMS_BLOCK_ITEM: 'MultipleItemsBlockItem',
  MULTIPLE_ITEMS_BLOCK: 'MultipleItemsBlock',
  MULTIPLE_ITEMS_COUNTY_BLOCK: 'MultipleItemsCountyBlock',
  LEAFLET_MAP_BLOCK: 'LeafletMapBlock',
  VIDEO_BLOCK: 'VideoBlock',
  MENU_BLOCK: 'MenuBlock',
  PARALLAX_BLOCK: 'ParallaxBlock',
  TEXT_BLOCK: 'TextBlock',
  COUNTY_CONTENT_AREA_BLOCK: 'CountyContentAreaBlock',
  DUAL_TEXT_BLOCK: 'DualTextBlock',
  RELATED_MATERIAL_BLOCK: 'RelatedMaterialBlock',
  COLLAPSIBLE_CONTAINER_BLOCK: 'CollapsibleContainerBlock',
  COLLAPSIBLE_BLOCK: 'CollapsibleBlock',
  COLLAPSIBLE_DOUBLE_BLOCK: 'CollapsibleDoubleBlock',
  RENT_CALCULATION_BLOCK: 'RentCalculationBlock',
  JOB_LIST_BLOCK: 'JobListBlock',
  JOB_APPLICATION_BLOCK: 'JobApplicationBlock',
  DYNAMIC_FEATURE_BLOCK: 'DynamicFeatureListBlock',
  SIDE_CARD_BLOCK: 'SideCardBlock'
};
