import * as TYPES from './countyFilterTypes';
import { fetchCountyFilters } from '../../api/ContentApi';
import { LocalStorage } from 'utils';
import queryString from 'query-string';

const Storage = new LocalStorage();

export const fetchFilters = () => {
  return async dispatch => {
    dispatch({ type: TYPES.FILTER_REQUESTED_STARTED });

    try {
      const countyFilters = await fetchCountyFilters();

      if (!countyFilters || !Array.isArray(countyFilters)) {
        dispatch({ type: TYPES.FILTER_ERROR });
        return [];
      }
      const cookie = Storage.getJSON(TYPES.FILTER_COUNTY_COOKIE);
      const county = queryString.parse(window.location.search);
      if (county !== undefined && cookie === null) {
        const countyfromUrl = countyFilters.find(
          ({ name }) => name.replace(/\s+/g, '') === county.region
        );
        if (countyfromUrl) {
          dispatch({ type: TYPES.FILTER_QUERY_COOKIE, selected: countyfromUrl });
        }
      }

      if (cookie !== null)
          Storage.set(TYPES.FILTER_COUNTY_COOKIE, { ...cookie, categoryList: countyFilters });
      if (county !== undefined) {
        const countyfromUrl = countyFilters.find(
          ({ name }) => name.replace(/\s+/g, '') === county.region
        );
        if (countyfromUrl) {
          dispatch({ type: TYPES.FILTER_SELECTED, selected: countyfromUrl });
        }
      }
      dispatch({ type: TYPES.FILTER_REQUESTED_FINISH, categoryList: countyFilters });

      return countyFilters;
    } catch (e) {
      throw e;
    }
  };
};

export const setMessage = (message, lastHitId) => {
  return dispatch => {
    dispatch({ type: TYPES.FILTER_SET_MESSAGE, message, lastHitId });
  };
};

export const setSelectedFilter = (filter, position, hideCounty = false) => {
  if (!hideCounty && filter.id !== 2) {
    window.history.replaceState('', '', `?region=${filter.name.replace(/\s+/g, '')}`);
  } else if (filter.id === 2) {
    window.history.replaceState('', '', window.location.pathname);
  }

  return async dispatch => {
    dispatch({ type: TYPES.FILTER_SET_SELECTED });

      const cookie = Storage.getJSON(TYPES.FILTER_COUNTY_COOKIE);
    const newCookie = { ...cookie, selected: filter };
      Storage.set(TYPES.FILTER_COUNTY_COOKIE, newCookie);

    dispatch({ type: TYPES.FILTER_SELECTED, selected: filter });
    if (position) {
      dispatch({ type: TYPES.FILTER_ANIMATION_START, value: position });
    }
  };
};

export const resetFilter = category => {
  return dispatch => {
      const cookie = Storage.getJSON(TYPES.FILTER_COUNTY_COOKIE);
    const newCookie = { ...cookie, selected: category, last: category, current: category };
    Storage.set(TYPES.FILTER_COUNTY_COOKIE, newCookie);
    dispatch({ type: TYPES.FILTER_RESET, category });
  };
};

export const setCurrentFilter = (filter, position) => {
  return dispatch => {
    dispatch({ type: TYPES.FILTER_SET_CURRENT });
    dispatch({ type: TYPES.FILTER_CURRENT, value: filter });
    if (position) {
      dispatch({ type: TYPES.FILTER_ANIMATION_START, value: position });
    }
  };
};

export const setHeaderHover = hovering => {
  return dispatch => {
    dispatch({ type: TYPES.FILTER_HEADER_HOVER, value: hovering });
  };
};

export const expandCollapse = expanded => {
  return dispatch => {
    dispatch({ type: TYPES.FILTER_EXPAND_COLLAPSE, value: expanded });
  };
};

export const setAnimationDone = () => {
  return dispatch => {
    dispatch({ type: TYPES.FILTER_ANIMATION_END });
  };
};

export const setHover = hover => {
  return dispatch => {
    if (hover) {
      dispatch({ type: TYPES.FILTER_HOVER_START });
    } else {
      dispatch({ type: TYPES.FILTER_HOVER_END });
    }
  };
};

export const toggleModal = (shouldOpen = false, forceMode = false) => {
  return dispatch => {
    dispatch({
      type: TYPES.FILTER_TOGGLE_MODAL,
      value: { shouldOpen: shouldOpen, forceMode: forceMode }
    });
  };
};
