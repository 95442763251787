import React, { PureComponent } from 'react';
import { BlockAreaContainer } from './index';
import { connect } from 'react-redux';
import { sizes } from 'style/media';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import HeroBlock from 'blocks/heroblock';
import SubHeroBlock from 'blocks/subheroblock';
import PrioritizedArticleBlock from 'blocks/prioritizedarticleblock';
import ActivityBlock from 'blocks/activityblock';
import LinkTextBlock from 'blocks/linktextblock';
import LinkListBlock from 'blocks/linklistblock';
import IconListBlock from 'blocks/iconlistblock';
import ContactBlock from 'blocks/contactblock';
import CustomAreaBlock from 'blocks/customareablock';
import CarouselBlock from 'blocks/carouselblock';
import CardCarouselBlock from 'blocks/cardcarouselblock';
import CardCarouselBlockItem from 'blocks/cardcarouselblockitem';
import ContractBlock from 'blocks/contractblock/ContractBlock';
import ContractCategoryBlock from 'blocks/contractcategoryblock';
import NewsBlock from 'blocks/newsblock';
import DualTextBlock from 'blocks/dualtextblock';
import PageListBlock from 'blocks/pagelistblock';
import InstructionContainerBlock from 'blocks/instructioncontainerblock';
import InstructionBlock from 'blocks/instructionblock';
import QuoteBlock from 'blocks/quoteblock';
import FilterBlock from 'blocks/filterblock';
import CollapsibleContainerBlock from 'blocks/collapsiblecontainerblock';
import CollapsibleBlock from 'blocks/collapsibleblock';
import FilterAreaBlock from 'blocks/filterareablock';
import MultipleItemsBlock from 'blocks/multipleitemsblock';
import ListBlock from 'blocks/listblock';
import PuffListBlock from 'blocks/pufflistblock';
import VideoBlock from 'blocks/videoblock';
import MenuBlock from 'blocks/menublock';
import ParallaxBlock from 'blocks/parallaxblock';
import TextBlock from 'blocks/textblock';
import CountyContentAreaBlock from 'blocks/countycontentareablock';
import OPE from 'components/common/EpiOnPageEdit';
import DefaultLoadable from 'utils/Loadable';
import RelatedMaterialBlock from 'blocks/relatedmaterialblock';
import RentCalculationBlock from 'blocks/rentcalculationblock';
import { JobListBlock } from 'blocks/joblistblock';
import { JobApplicationBlock } from 'blocks/jobapplicationblock';
import DynamicFeatureBlock from 'blocks/dynamicfeatureblock';
import SideCardBlock from 'blocks/sidecardblock';

import { BLOCKS } from 'constants/blockTypes';
import { ArrowLink } from 'features/ArrowLink';

const MultinetDynamicFilterBlock = DefaultLoadable(() =>
  import('blocks/multinetdynamicfilterblock' /* webpackChunkName: "multinetdynamicfilterblock" */)
);
const MultinetCourseBlock = DefaultLoadable(() =>
  import('blocks/multinetcourseblock' /* webpackChunkName: "multinetcourseblock" */)
);
const EpiForm = DefaultLoadable(() => import('features/EpiForm' /* webpackChunkName: "EpiForm" */));
const CalendarListBlock = DefaultLoadable(() =>
  import('blocks/calendarlistblock' /* webpackChunkName: "calendarlistblock" */)
);
const ContactListBlock = DefaultLoadable(() =>
  import('blocks/contactlistblock' /* webpackChunkName: "contactlistblock" */)
);

class BlockArea extends PureComponent {
  getBlocks = () => {
    const { nopadding, contentarea = [] } = this.props;

    if (!contentarea || !contentarea.length) return null;
    const blocks = contentarea.map((block, i) => {
      const props = {
        ...block,
        nopadding,
        first: i === 0 && i === 0 ? true : false
      };
      return this.getBlockAsComponent(props, `${i}`, `block_${i}`);
    });

    return blocks;
  };

  getBlockAsComponent = (props, indexKey, key) => {
    var index = props.id || indexKey;
    let block;
    switch (props.blockType) {
      case BLOCKS.HERO:
        block = <HeroBlock key={index} {...props} />;
        break;
      case BLOCKS.SUB_HERO:
        this.props.subMenuMobile
          ? (block = (
              <SubHeroBlock
                key={index}
                subMenuMobile={this.props.subMenuMobile}
                opacity={this.props.opacity}
                {...props}
              />
            ))
          : (block = <SubHeroBlock key={index} opacity={this.props.opacity} {...props} />);
        break;
      case BLOCKS.RELATED_MATERIAL_BLOCK:
        block = <RelatedMaterialBlock key={index} {...props} />;
        break;
      case BLOCKS.LIST:
        block = <ListBlock key={index} {...props} />;
        break;
      case BLOCKS.PUFF_LIST_BLOCK:
        block = <PuffListBlock key={index} {...props} />;
        break;
      case BLOCKS.CALENDAR_LIST:
        block = <CalendarListBlock key={index} {...props} />;
        break;
      case BLOCKS.PRIORITIZED_ARTICLE:
        block = <PrioritizedArticleBlock key={index} {...props} />;
        break;
      case BLOCKS.ACTIVITY:
        block = (
          <LazyLoad key={index} height={500} offset={600} unmountIfInvisible={false}>
            <ActivityBlock {...props} />
          </LazyLoad>
        );
        break;
      case BLOCKS.QUESTION:
        block = <LinkTextBlock key={index} {...props} />;
        break;
      case BLOCKS.CAROUSEL:
        block = (
          <LazyLoad key={index} height={200} offset={600} unmountIfInvisible={false}>
            <CarouselBlock key={index} {...props} />
          </LazyLoad>
        );
        break;
      case BLOCKS.CARD_CAROUSEL:
        block = <CardCarouselBlock key={index} {...props} />;
        break;
      case BLOCKS.CARD_CAROUSEL_ITEM:
        block = <CardCarouselBlockItem key={index} {...props} />;
        break;
      case BLOCKS.PAGE_LIST:
        block = <PageListBlock key={index} {...props} />;
        break;
      case BLOCKS.COUNTY_FILTER:
        block = <FilterBlock key={index} {...props} />;
        break;
      case BLOCKS.INSTRUCTION_CONTAINER_BLOCK:
        block = <InstructionContainerBlock key={index} {...props} />;
        break;
      case BLOCKS.INSTRUCTION_BLOCK:
        block = <InstructionBlock key={index} {...props} />;
        break;
      case BLOCKS.RENT_CALCULATION_BLOCK:
        block = <RentCalculationBlock key={index} {...props} />;
        break;
      case BLOCKS.COLLAPSIBLE_CONTAINER_BLOCK:
        block = <CollapsibleContainerBlock key={index} {...props} />;
        break;
      case BLOCKS.COLLAPSIBLE_BLOCK:
        block = <CollapsibleBlock key={index} {...props} />;
        break;
      case BLOCKS.FILTER_AREA:
        block = <FilterAreaBlock key={index} {...props} />;
        break;
      case BLOCKS.LINK_LIST:
        block = <LinkListBlock key={index} {...props} />;
        break;
      case BLOCKS.NEWS_BLOCK:
        block = (
          <LazyLoad key={index} height={200} offset={500} unmountIfInvisible={false}>
            <NewsBlock {...props} />
          </LazyLoad>
        );
        break;
      case BLOCKS.DUAL_TEXT_BLOCK:
        block = <DualTextBlock key={index} {...props} />;
        break;
      case BLOCKS.ICON_LIST_BLOCK:
        block = <IconListBlock key={index} {...props} />;
        break;
      case BLOCKS.CONTACT_LIST_BLOCK:
        block = (
          <LazyLoad key={index} height={200} offset={500} unmountIfInvisible={false}>
            <ContactListBlock {...props} />
          </LazyLoad>
        );
        break;
      case BLOCKS.CONTACT_BLOCK:
        block = <ContactBlock key={index} {...props} />;
        break;
      case BLOCKS.CONTRACT_BLOCK:
        block = <ContractBlock key={index} {...props} />;
        break;
      case BLOCKS.CONTRACT_CATEGORY_BLOCK:
        block = <ContractCategoryBlock key={index} {...props} />;
        break;
      case BLOCKS.CUSTOMAREA_BLOCK:
        block = <CustomAreaBlock key={index} {...props} />;
        break;
      case BLOCKS.EPIFORM:
        block = <EpiForm key={index} {...props} />;
        break;
      case BLOCKS.EXTENDED_EPIFORM:
        block = <EpiForm key={index} {...props} />;
        break;
      case BLOCKS.LINK_BLOCK:
        const { link } = props;
        block = <ArrowLink key={index} secondary {...link} center="true" />;
        break;
      case BLOCKS.QUOTE_BLOCK:
        block = <QuoteBlock key={index} {...props} />;
        break;
      case BLOCKS.VIDEO_BLOCK:
        block = <VideoBlock key={index} {...props} />;
        break;
      case BLOCKS.MULTINET_COURSE_BLOCK:
        block = (
          <MultinetCourseBlock
            key={index}
            buttonInsteadOfArrow={this.props.buttonInsteadOfArrow}
            {...props}
          />
        );
        break;
      case BLOCKS.MULTINET_DYNAMIC_FILTER_BLOCK:
        block = <MultinetDynamicFilterBlock key={index} {...props} />;
        break;
      case BLOCKS.MULTIPLE_ITEMS_BLOCK:
        block = <MultipleItemsBlock key={index} {...props} />;
        break;
      case BLOCKS.MULTIPLE_ITEMS_COUNTY_BLOCK:
        block = <div key={index} {...props} />;
        break;
      case BLOCKS.COUNTY_CONTENT_AREA_BLOCK:
        block = <CountyContentAreaBlock key={index} {...props} />;
        break;
      case BLOCKS.MENU_BLOCK:
        block = <MenuBlock key={index} {...props} />;
        break;
      case BLOCKS.PARALLAX_BLOCK:
        if (window.innerWidth < sizes.desktop) {
          block = (
            <ParallaxBlock key={key} subMenuMobile={true} opacity={this.props.opacity} {...props} />
          );
        } else {
          block = <ParallaxBlock key={key} opacity={this.props.opacity} {...props} />;
        }
        break;
      case BLOCKS.TEXT_BLOCK:
        block = <TextBlock key={key} {...props} />;
        break;
      case BLOCKS.JOB_LIST_BLOCK:
        block = <JobListBlock key={key} {...props} />;
        break;
      case BLOCKS.JOB_APPLICATION_BLOCK:
        block = <JobApplicationBlock key={key} {...props} />;
        break;
      case BLOCKS.DYNAMIC_FEATURE_BLOCK:
        block = <DynamicFeatureBlock key={key} {...props} />;
        break;
      case BLOCKS.SIDE_CARD_BLOCK:
        block = <SideCardBlock key={key} {...props} />;
        break;
      default:
        break;
    }
    return block ? block : null;
  };

  render() {
    const blocks = this.getBlocks();
    const { children, contentarea, propertyName, nomargin, ...rest } = this.props;
    return (
      <OPE propertyName={propertyName}>
        {(blocks || children) && (
          <BlockAreaContainer nomargin={!!nomargin} {...rest}>
            {blocks && blocks}
            {children && children}
          </BlockAreaContainer>
        )}
      </OPE>
    );
  }
}

const mapStateToProps = state => ({
  filter: {
    current: state.filter.filter,
    loading: state.filter.loading
  }
});

export default connect(mapStateToProps)(BlockArea);

BlockArea.propTypes = {
  propertyName: PropTypes.string.isRequired,
  nopadding: PropTypes.bool,
  nomargin: PropTypes.bool,
  children: PropTypes.any,
  contentarea: PropTypes.any,
  heroBlock: PropTypes.arrayOf(
    PropTypes.shape({
      blockType: PropTypes.oneOf(['HeroBlock']).isRequired,
      buttonLink: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      image: PropTypes.string,
      text: PropTypes.string.isRequired
    })
  ),
  articleBlock: PropTypes.arrayOf(
    PropTypes.shape({
      blockType: PropTypes.oneOf(['ArticleBlock']).isRequired,
      buttonLink: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      image: PropTypes.string,
      imageToRight: PropTypes.bool,
      text: PropTypes.string.isRequired
    })
  ),
  carouselBlock: PropTypes.arrayOf(
    PropTypes.shape({
      blockType: PropTypes.oneOf(['CarouselBlock']),
      id: PropTypes.number,
      heading: PropTypes.string.isRequired,
      textHeading: PropTypes.string,
      ingress: PropTypes.string,
      carouselItems: PropTypes.arrayOf(
        PropTypes.shape({
          imageUrl: PropTypes.string.isRequired,
          linkUrl: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired
        })
      )
    })
  ),
  questionBlock: PropTypes.arrayOf(
    PropTypes.shape({
      blockType: PropTypes.oneOf(['QuestionBlock']).isRequired,
      id: PropTypes.number,
      heading: PropTypes.string.isRequired,
      ingress: PropTypes.string.isRequired,
      questions: PropTypes.arrayOf(
        PropTypes.shape({ link: PropTypes.string.isRequired, text: PropTypes.string.isRequired })
      )
    })
  ),
  listBlock: PropTypes.arrayOf(
    PropTypes.shape({
      blockType: PropTypes.oneOf(['ListBlock']).isRequired,
      id: PropTypes.number,
      listItems: PropTypes.arrayOf(
        PropTypes.shape({
          imageUrl: PropTypes.string.isRequired,
          ingress: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          tag: PropTypes.string.isRequired,
          linkUrl: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired
        })
      )
    })
  ),
  courseOccationBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      blockType: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          year: PropTypes.string.isRequired,
          monthItems: PropTypes.arrayOf(
            PropTypes.shape({
              month: PropTypes.string.isRequired,
              items: PropTypes.arrayOf(
                PropTypes.shape({
                  city: PropTypes.string.isRequired,
                  month: PropTypes.string,
                  day: PropTypes.string,
                  year: PropTypes.string,
                  id: PropTypes.number.isRequired,
                  startDate: PropTypes.string.isRequired,
                  bookedPercentage: PropTypes.number.isRequired,
                  link: PropTypes.string.isRequired
                })
              )
            })
          )
        })
      )
    }).isRequired
  )
};
